<template>
	<div :class="isMobile ? 'inline' : 'flex'">
		<span
			v-for="(dropdown, index) in dropdownButtons"
			:key="`dropdown-${index}`"
			class="inline-block py-1 mx-1"
		>
			{{ dropdown[index] }}
			<WwDropdown
				:menu-items="dropdown.filters"
				:reset-checkboxes="resetActiveFilters"
				:initial-active-items="activeFilters"
				:button-id="dropdown.id"
				:parent-reference-level="2"
				:parent-reference-name="parentRefName"
				show-top-arrow
				:center-top-arrow="!isMobile"
				:x-offset-override="isMobile ? null : 56"
				@menu-clicked="passFilterId"
				@expanded="handleMenuExpanded"
			>
				<template #button="{ clicked }">
					<button
						:id="dropdown.name"
						type="button"
						:class="[
							'items-center px-3.5 py-1 rounded-full bg-white font-bold flex focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50',
							active('checkbox', dropdown.id) ? 'border-green-500 border-2' : 'border m-px border-gray-300'
						]"
						aria-expanded="true"
						aria-haspopup="true"
						:aria-label="dropdown.name"
						@click="clicked"
					>
						{{ dropdown.name }}
						<span class="ml-2">
							<ArrowTriangle
								:class="[
									'transition-all ease-in',
									{ 'transform rotate-180': isMenuExpanded && activeId === dropdown.id }
								]"
							/>
						</span>
					</button>
				</template>
			</WwDropdown>
		</span>
	</div>
</template>

<script async>
import ArrowTriangle from '@/components/icons/ArrowTriangle.vue'

export default {
	components: {
		WwDropdown: () => import('@/components/UI/WwDropdown.vue'),
		ArrowTriangle
	},
	props: {
		isMobile: {
			type: Boolean,
			required: true
		},
		dropdownButtons: {
			type: Array,
			required: true
		},
		activeFilters: {
			type: Array,
			required: true
		},
		resetActiveFilters: {
			type: Boolean,
			default: false
		},
		parentRefName: {
			type: String,
			default: ''
		}
	},
	emits: [ 'menu-clicked' ],
	data() {
		return {
			isMenuExpanded: false,
			activeId: ''
		}
	},
	methods: {
		handleMenuExpanded(isExpanded, expandedId) {
			this.isMenuExpanded = isExpanded
			this.activeId = expandedId
		},
		findWithAttr(array, attr, value) {
			for (let i = 0; i < array.length; i += 1) {
				if (array[i][attr] === value) {
					return i
				}
			}
			return -1
		},
		active(type, value) {
			const buttonIndex = this.findWithAttr(this.dropdownButtons, 'id', value)
			switch (type) {
				case 'checkbox':
					for (let i = 0; i < this.dropdownButtons[buttonIndex].filters.length; i++) {
						const item = this.dropdownButtons[buttonIndex].filters[i].id
						if (this.activeFilters.includes(item)) {
							return true
						} else {
							continue
						}
					}
					return false
				default:
					return false
			}
		},
		passFilterId(value) {
			this.$emit('menu-clicked', value)
		}
	}
}
</script>
