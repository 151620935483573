var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isMobile ? 'inline' : 'flex'},_vm._l((_vm.dropdownButtons),function(dropdown,index){return _c('span',{key:`dropdown-${index}`,staticClass:"inline-block py-1 mx-1"},[_vm._v("\n\t\t"+_vm._s(dropdown[index])+"\n\t\t"),_c('WwDropdown',{attrs:{"menu-items":dropdown.filters,"reset-checkboxes":_vm.resetActiveFilters,"initial-active-items":_vm.activeFilters,"button-id":dropdown.id,"parent-reference-level":2,"parent-reference-name":_vm.parentRefName,"show-top-arrow":"","center-top-arrow":!_vm.isMobile,"x-offset-override":_vm.isMobile ? null : 56},on:{"menu-clicked":_vm.passFilterId,"expanded":_vm.handleMenuExpanded},scopedSlots:_vm._u([{key:"button",fn:function({ clicked }){return [_c('button',{class:[
						'items-center px-3.5 py-1 rounded-full bg-white font-bold flex focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50',
						_vm.active('checkbox', dropdown.id) ? 'border-green-500 border-2' : 'border m-px border-gray-300'
					],attrs:{"id":dropdown.name,"type":"button","aria-expanded":"true","aria-haspopup":"true","aria-label":dropdown.name},on:{"click":clicked}},[_vm._v("\n\t\t\t\t\t"+_vm._s(dropdown.name)+"\n\t\t\t\t\t"),_c('span',{staticClass:"ml-2"},[_c('ArrowTriangle',{class:[
								'transition-all ease-in',
								{ 'transform rotate-180': _vm.isMenuExpanded && _vm.activeId === dropdown.id }
							]})],1)])]}}],null,true)})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }